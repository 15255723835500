.handlerIcon{
  position: absolute;
  width: 12px;
  height: 12px;
  top: -5px;
  left: -5px;
  border-radius:3px;
  img{
    width: 100%;
    height: 100%;
    border-radius:3px;
  }
}

.user-chat-topbar{
  .handlerIcon{
    top: -8px;
    left: -8px;
    width: 15px;
    height: 15px;
  }
}
.profileTopCont{
  .handlerIcon{
    top: -8px;
    left: -8px;
    width: 15px;
    height: 15px;
  }
}

.userProfileDetailLabel{
  display: flex;
  flex-direction: row;
  .handlerIcon{
    position: relative;
    top:auto;
    left: auto;
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 10px;
    border-radius:5px;
    img{
      border-radius:5px;
    }
  }
}

.membersList{
  .handlerIcon{
    width: 12px;
    height: 12px;
    top: -8px;
    left: -8px;
    border-radius:3px;
    img{
      border-radius:3px;
    }
  }
}

.conversation-list{
  .chat-avatar{
    position: relative;
    .handlerIcon{
      width: 15px;
      height: 15px;
      top: -8px;
      left: -8px;
      border-radius:5px;
      img{
        width: 100%;
        height: 100%;
        border-radius:5px;
      }
    }
  }
}

.chat-input-section{
  position: relative;
}

.needAccessKey{
  margin-left: 10px;
}

.chat-leftsidebar{
  .channelAbout{
    display: block;
  }
  .channelFirstMessage{
    display: block;
  }
}

#chatinput-form{
  position: relative;
}

.dropzoneCont{
  position: absolute;
  z-index: 10;
  width: 100%;
  bottom: 0px;
  height: 120px;
  border-radius: 10px;
  display: none;
  text-align: center;
}

.dropzoneCont.active{
  display: flex;
  flex-direction: row;
}

.dropzoneCont{
  background-color: #fefefe;
}

[data-layout-mode="dark"] .dropzoneCont{
  background: #262626;
}
.dropzoneCont i{
  font-size: 2em;
}

.dropzoneCont div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.links-list-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainLogoAuth{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  svg{
    max-width: 100%;
    max-height: 100%;
  }
}

body{
  .auth-bg {
    .text-muted{
      color: rgba(255, 255, 255, 0.5) !important;
    }
    .form-check-input{
      background-color: #3b5998 !important;
    }
    .auth-img{
      margin-bottom: 17px;
      max-width: 180%;
    }
  }
}

.auth-bg {
  //background-color: #344e86;
  background-color: #3b5998;
  color: #ffffff;
  .text-muted{
    color: rgba(255, 255, 255, 0.5) !important;
  }
  overflow: hidden;
}
.auth-bg h1, .auth-bg h2, .auth-bg h3, .auth-bg h4, .auth-bg h5{
  color: #ffffff !important;
}

.authentication-page-content{
  //background-color: #3b5998 !important;
  background-color: #344e86 !important;
}

.auth-bg .form-control {
  background: #3b5998 !important;
  background-color: #3b5998 !important;
  color: #ffffff !important;
  border: 1px solid #344e86 !important;
}
.auth-bg .form-control::placeholder{
  color: rgba(255, 255, 255, 0.5) !important;
}
.auth-bg .avatar-title{
  overflow: hidden;
}
.auth-bg .avatar-title .logoPlaceholder{
  width: 70%;
  height: 70%;
}
.mainLogoAuthDescription{
  //color: #21a9e1;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 40px;
  font-size: 10px;
  font-family: Verdana;
  //font-family: Montserrat;
}

.mainMessengerCont{
  height: 100vh;
}


.emptyChannelsListCaption, .authBgCollapsedCaption{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.emptyChannelsListCaptionWrapper, .authBgCollapsedCaptionWrapper{
  color: rgba(0,0,0,0.3);
  padding: 24px;
}

.authBgCollapsedCaption{
  display: none;
}

[data-layout-mode="dark"] .emptyChannelsListCaptionWrapper, [data-layout-mode="dark"] .authBgCollapsedCaptionWrapper{
  color: rgba(255,255,255,0.3);
}

.chat-welcome-section {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 991.98px) {
    display: none;
  }
}

.profile-media-img {
  display: flex;
  gap: 8px;

  .media-img-list {
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 76px;
        height: 76px;
        object-fit: cover;
        display: flex;
      }

      .bg-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
    }
  }
}

//.chat-leftsidebar{
//  height: auto !important;
//  flex-direction: column;
//  flex-grow: 1;
//}

.emojiPickerMessengerCont{
  position: absolute;
  z-index: 2;
  bottom: 40px;
}

body{
  font-family: $font-family-primary;
}

.was-validated .btn.btn-link{
  right: 20px !important;
}

.was-validated .invalid-feedback{
  display: block;
}
.was-validated .valid-feedback{
  display: block;
}

.mcLoader .status{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mcLoader img{
  display: flex;
  max-width: 150px;
  max-height: 70px;
}

.mcLoader.sm img{
  display: flex;
  max-width: 60px;
  max-height: 30px;
}
.handlerIconTopLeft{
  top:-5px;
  left:-5px;
  width: 22px;
  height: 22px;
}
.handlerIconTopLeft img{
  width: 100%;
  height: 100%;
}
.userItemLine .handlerIconTopLeft{
  top:-10px;
  left:-10px;
}
.statusIndicator{
  width: 5px;
  height: 5px;
}

.chat-conversation {
  height: calc(100vh - 292px);
}

.chat-room-list {
  max-height: calc(100vh - 262px);
}

.messengerSidebar .chat-wrapper{
  height: 100%;
}
.messengerSidebar .chat-leftsidebar{
  height: 100vh;
}

.messengerSidebar{
  position: fixed;
  right: 0px;
  top:0px;
  height: 100%;
  z-index: 1045;
  width: 80vw;
  transition: right 0.5s ease;
  opacity: 1;
}
.messengerSidebar.collapsed{
  right: -80vw;
  opacity: 0;
}

.messengerSidebarOverlay{
  z-index: 1003;
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top:0px;
  left: 0px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease;
}

.messengerSidebarOverlay.visible{
  opacity:1;
}

.messengerSidebar .chat-room-list{
  max-height: calc(100vh - 112px);
}

.messengerSidebar .chat-conversation {
  height: calc(100vh - 155px) !important;
}

.mainRightBar{
  padding-top: 120px;
  width: 80px;
  height: 100%;
  position: fixed;
  right: 0px;
  top: 0px;
  overflow: hidden;
}

.mainRightBar .chat-room-list{
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  height: calc(100vh - 350px) !important;
  overflow-x: hidden;
}

.mainRightBar .chat-message-list{
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}

.mainRightBar .chat-list > li a{
  padding-left: 18px;
  padding-right: 18px;
}

.mainRightBar .channelMenuItemUnread{
  position: absolute;
  bottom: 5px;
  left: 45px;
}

.mainRightBar  .simplebar-track.simplebar-vertical{
  width: 4px;
}

.pageContentWrapper{
  margin-right: 84px;
}

.pageContentMessenger {
  padding: calc(70px + .1rem) calc(.5rem * 0.1) 62px calc(.5rem * 0.1);
}

#back-to-top{
  right: 19px;
}

.customizer-setting{
  right: 12px;
}

.mainRightBarBottom{
    padding-bottom: 71px;
}

.mainRightBarBottomSetting{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#back-to-top{
  right: 100px;
  bottom: 75px;
}

.cabinetComingSoonCont .shape{
  z-index: auto;
}

.borderBottomUnRounded{
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.borderTopUnRounded{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.emojiButtonCont{
  position: relative;
  z-index: 1;
}

.bg-overlay-blue{
  background: linear-gradient(to right, darken($primary, 6%), $primary);
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cabinetEditButton{
  position: absolute;
  top: 11px;
  right: 16px;
  z-index: 2;
}

.cabinetEditButton{
  opacity: 0;
  transition: opacity 0.5s ease;
}

.cabinetEditButton.visible{
  opacity:1;
}

.iconBadge {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: $primary;
}

.mw-0{
  min-width: 0;
}

.ReactModalPortal{
  position: relative;
  z-index: 10000;
}

.spinner-border-sm {
  --vz-spinner-border-width: 0.10em;
}


.ratingSmile{
  font-size: 3em;
  transition: opacity 0.5s ease;
  filter: grayscale(1);
  opacity: 0.7;
  cursor: pointer;
}

.ratingSmile:hover, .ratingSmile.active{
  filter: none;
  opacity: 1;
}

.ratingSmile.small{
  font-size: 2em;
}

.toastLabelIcon{
  position: absolute;
  bottom: -3px;
  right: -8px;
}

.toast-notification {
  background: var(--vz-info) !important;
}

.react-international-phone-input {
  padding: 0.5rem 0.9rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5;
  height: var(--react-international-phone-height, 39px) !important;
}
.react-international-phone-country-selector-button{
  padding: 0.5rem 0.9rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5;
  height: var(--react-international-phone-height, 39px) !important;
}

.customValidation .form-control.is-invalid {
  border-color: #f17171 !important;
  padding-right: calc(1.5em + 1rem);
  background-image: url('data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270%200%2012%2012%27%20width=%2712%27%20height=%2712%27%20fill=%27none%27%20stroke=%27%23f17171%27%3e%3ccircle%20cx=%276%27%20cy=%276%27%20r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27%20d=%27M5.8%203.6h.4L6%206.5z%27/%3e%3ccircle%20cx=%276%27%20cy=%278.2%27%20r=%27.6%27%20fill=%27%23f17171%27%20stroke=%27none%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

//.__rdt_custom_sort_icon__.asc i, .__rdt_custom_sort_icon__.asc svg{
//  -webkit-transform: rotate(180deg) !important;
//  -ms-transform: rotate(180deg) !important;
//  transform: rotate(180deg) !important;
//}

.__rdt_custom_sort_icon__.asc i:before {
  content: "\f04bd";
  //font-family: "Material Design Icons";
}

.chat-user-img .user-status {
  background-color: #878a99;
}

.mt-n100{
  margin-top: -100%;
}

.disabledBlock{
  filter: grayscale(1);
  opacity: 0.7;
}

.fc-timegrid-event-harness  {
  width: 100% !important;
}

.fc-right-time-section{
  background: rgba(255,255,255,0.8);
}

[data-layout-mode="dark"] .fc-right-time-section{
  background: transparent;
}

.calendarLoader{
  height: 30px;
  overflow: hidden;
}

.kanbanCont{
  background: transparent;
  background-color: transparent !important;
  padding: 0px !important;
}
.kanbanWrapper{
  margin-left: -5px;
}
.rangePickerInput{
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.rangePickerInputCont{
  min-width: 258px;
}

.react-trello-lane{
  background-color: transparent !important;
  border-radius: 5px;
}

.xxxs{
  width: 15px;
  height: 15px;
}
.detailActivityViewCard{
  max-width: 270px;
}
.detailActivityViewCardEmptyIcon{
  font-size: 50px;
  opacity: 0.2;
}
.detailActivityViewCardWide .detailActivityViewCard{
  max-width: none;
}

.editorEmojiCont, .editorLinkCont{
  left:auto !important;
  right: 0px !important;
}
.editorDraftAreaCont{
  border: 1px solid #F1F1F1;
  border-top:0px;
  padding: 10px;
  height: 200px;
}
.editorDraftToolbarAreaCont{
  margin-bottom: 0px !important;
}

.editorLinkCont .rdw-link-modal-input{
  display: block;
  width: 100%;
  padding: 1rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--vz-input-border);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.editorLinkCont .rdw-link-modal-label{
  margin-bottom: 0px;

}

.templateButtonCont{
  position: absolute;
  top: 30px;
  right: -1px;
}

.rdw-option-wrapper{
  border: 0px;
}

.user-chat {
  background: url("../../resources/images/messenger/messengerBgWhite.png");
  background-color: var(--#{$prefix}body-bg);
  background-size: 150px;
  //background: $input-bg;
}

[data-layout-mode="dark"] .user-chat {
  background: url("../../resources/images/messenger/messengerBgBlack.png");
  background-color: var(--#{$prefix}body-bg);
  background-size: 150px;
//  background: $input-bg;
}

[data-layout-mode="dark"] .react-international-phone-country-selector-button,
[data-layout-mode="dark"] .react-international-phone-country-selector-button:hover
{
  background: $input-bg;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  color: $input-color;

  //background: transparent;
  //border-color: var(--vz-border-color);
  //color: var(--vz-body-color);
}

[data-layout-mode="dark"]  .react-international-phone-input-container .react-international-phone-input{
  background: transparent;
  //border-color: var(--vz-border-color);
  //color: var(--vz-body-color);
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  color: $input-color;
}

[data-layout-mode="dark"]  .react-international-phone-country-selector-dropdown{
  //border-color: var(--vz-border-color);
  //color: var(--vz-body-color);
  //background-color: var(--vz-card-bg);
  background: $input-bg;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  color: $input-color;
}

[data-layout-mode="dark"] .react-international-phone-country-selector-dropdown__list-item:hover{
  //background-color: var(--vz-body-bg);
  background: $input-bg;
}

.zindex-3{
  z-index: 3;
}

.upcoming-scheduled .flatpickr-months{
  display:none;
}

.compareItemCont{
  max-width: 280px;
  width: 280px;
}

.cardHandlerItem{
  padding: 10px !important;
}

.animate-small{
  transition: all 0.4s;

  &:hover{
    transform: translateY(calc(#{-$grid-gutter-width} / 5));
  }
}

.addNewHandlerSmallButton{
  padding-top: 2px;
  padding-left: 2px;
}

.fs-3rem{
  font-size: 3rem;
}

.wizardNoticeImageCont{
  width: 200px ;
}

.upgradeNoticeImageCont{
  width: 130px ;
}

.binance-grey-logo-svg{
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url("../../resources/images/logo/binance-logo.svg");
  background-size: 100%;
}

.binance-icon-diff{
  margin-top: -1px;
}

.countrySelectCont{
  width: 100%;
}

.input-step input{
  width: 100%;
}
.page-content .fc-direction-ltr .fc-daygrid-event.fc-event-end, .page-content .fc-direction-rtl .fc-daygrid-event.fc-event-start, .page-content .fc-event{
  padding: 0px !important;
  margin: 1px !important;
}
.calendarTimeBadge{
 padding-bottom: 1px;
}

.avatar-xxxs{
  width: 1rem;
  height: 1rem;
}
.statusIndicatorCont{
  position: absolute;
  top:0px;
  left: -2px;
}

.rightToolBarCont{
  position: absolute;
  right: 0px;
  top: 0px;
}

.emojiButtonContWrapper{
  top:0px;
  right: 0px;
}

.rightToolBarEditorMargin{
  padding-right: 45px;
}

.selectContUpdate{
  min-height: 40px !important;
  background-color: var(--vz-secondary-bg) !important;
}
.smallIdeasBlock{
  max-width: 200px;
}

.fc .fc-daygrid-day.fc-day-today{
  background-color: rgba(140, 207, 127, 0.15) !important;
}

.dropzone .dz-message{
  margin: 0px !important;
}
.dropzone {
  min-height: 130px;
  height: 130px;
  margin-bottom: 0;
}
.dropzoneMainCont{
  margin-bottom: 15px;
}

.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}

.currencyIconBottomRight{
  bottom: 0px;
  right: -13px;
}

.overflowInitial{
  overflow: unset !important;
}
.font-weight-normal{
  font-weight: normal;
}
.lh-2{
  line-height: 2;
}
.lh-1-5{
  line-height: 1.5;
}

.priceBlock{
  width: 200px;
}

.jvectormap-zoomin{
  left: auto !important;
  right: 0px !important;
  background: rgba(61, 120, 227, 0.1) !important;
  color: #3d78e3 !important;
}

.jvectormap-zoomout{
  left: auto !important;
  right: 0px !important;
  background: rgba(61, 120, 227, 0.1) !important;
  color: #3d78e3 !important;
}

.jvectormap-tip{
  background: #3d78e3 !important;
  color: #fff !important;
  padding: 4px 10px;
  transform: translateY(calc(-1.5rem / 5));
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  border: 0px !important;
}

.custom-hover-nav-tabs.smallTabs .nav-item .nav-link {
  width: 100px;
  height: 25px;
  position: relative;
  border-radius: 0px;
}
.custom-hover-nav-tabs.smallTabs h6{
  font-size: 0.7rem;
  font-weight: 400;
}

.carProductProperties span:after{
  content: ", ";
}

.carProductProperties span:last-of-type:after{
  content: "";
}

.formSelectPaging{
    line-height: 1.22;
    border-color: var(--vz-border-color);
}

.marginBottom0 {
  margin-bottom: 0 !important;
}

.maxWidth120px{
  max-width: 120px !important;
}
.maxWidth150px{
  max-width: 150px !important;
}

.w50px{
  width: 50px !important;
}
.w100px{
  width: 100px !important;
}
.w120px{
  width: 120px !important;
}
.w130px{
  width: 130px !important;
}
.w150px{
  width: 150px !important;
}
.w200px{
  width: 150px !important;
}

.offcanvas.showPreviewPopup{
  width: 800px;
  display: flex;
  flex-direction: column;
}

.showPreviewCont{
  display: flex;
  flex-direction: row;
}

.showPreviewPopup .showPreviewItem{
  width: 400px;
}

.offcanvasTopCont, #peerActivityEdit .offcanvas-title{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
}

.offcanvasTopTitle{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.previewMobile{
  max-width: 250px;
  width: 250px;
}
.previewDesktop{
  max-width: none;
  width: 100%;
}

.peerActivityPreviewCont{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.showExpandEditView{
  width: 80% !important;
}

.showEditItem{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.previewReelChannelCont{
   bottom:20px;
  z-index: 2;
  left: 20px;
}
.previewReelChannelCont .avatar-sm{
  width: 2.2rem;
  height: 2.2rem;
}

.previewReelChannelCont .avatar-title, .previewReelChannelCont .avatar-title img{
  padding: 0 !important;
}
.ctaPreviewButtonIcon{
    position: absolute;
    top: 0px;
    right: 5px;
}


.icon-rotate180 {
    -webkit-transform: rotate(180deg) !important;
    -ms-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
}
.showMultiPostingView{
  min-width: 800px;
}

.showPreviewPopup.showMultiPostingView{
  min-width: 1200px;
}

.multiPostingFormCont, .showPreviewItemCont{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 800px;
}
.showMultiPostingView .peerActivityFormColItem, .showPreviewItemCont .peerActivityFormColItem{
  display: flex;
  flex-direction: column;
  width: 400px;
}
.multiPostingFormMainEdit{
  display: flex;
  flex-grow: 1;
}

.showPreviewPopup .peerActivityModalFooter {
  margin-right: 400px;
}

.showExpandEditView .peerActivityFormColItem{
  flex-grow: 1;
}

.z-0{
  z-index: 0 !important;
}
.z-1{
  z-index: 1 !important;
}
.z-2{
  z-index: 2 !important;
}


/* mentions */
.mentions {

}

.mentions__suggestions{
  border-radius: $input-border-radius;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  padding-left: 10px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  color: $input-color;
}

.mentions--multiLine .mentions__control {
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 83px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  outline: 0;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  color: $input-color;
}
.mentions__suggestions__list {
  background-color: $input-bg;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
}
.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.mentions__suggestions__item--focused {
  background-color: $dropdown-link-hover-bg;
  color: $dropdown-link-hover-color;
}
.mentions__mention {
  position: relative;
  z-index: 1;
  color: $nav-link-hover-color;
  text-decoration: none;
  pointer-events: none;
  border-bottom: 1px dashed $nav-link-hover-color;
}

.apexcharts-text{
  color: $input-color !important;
  fill: $input-color !important;
}

.apexcharts-heatmap-rect{
  fill: $input-bg !important;
}
.pt10px{
  padding-top: 10px;
}

.navbar-menu .navbar-nav .nav-sm .nav-link:before{
    top:50%;
  margin-top: -1px;
}

[data-layout="twocolumn"] .navbar-header{
  padding-right: 0!important;
}
[data-layout="vertical"] .navbar-header{
  padding-right: 0!important;
}

[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .badge{
  display: inline-block !important;
  vertical-align: top !important;
  margin-left: 5px !important;
  margin-top: 2px !important;
}

.fc-day:hover{
  background:rgba($primary,.1) !important;
  cursor: pointer;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
